<template>
  <div class="block">
    <!-- <img
      class="block-shang"
      :src="require('@/assets/images/YuliApp/attribute/png_shang@2x.png')"
    /> -->
    <div class="block-content">
      <div class="block-content-abbr" :style="abbrStyle">{{ abbr }}</div>
      <div class="block-content-description">
        <p class="block-content-description-title">{{ localesPackage?.['chiprule_chip_desc']?.[localesEnv] }}</p>
        <p class="block-content-description-content">{{ description }}</p>
      </div>
      <div class="block-content-enhancement">
        <p class="block-content-enhancement-title">{{ localesPackage?.['chiprule_chip_attr']?.[localesEnv] }}</p>
        <p class="block-content-enhancement-content">
          {{ enhancement_attributes }}
        </p>
      </div>
    </div>
    <!-- <img
      class="block-xia"
      :src="require('@/assets/images/YuliApp/attribute/png_xia@2x.png')"
    /> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { ref } from "vue";
export default {
  props: {
    abbr: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    enhancement_attributes: {
      type: String,
      default: "",
    },
  },
  // data() {
  //   return {};
  // },
  computed: {
    ...mapState({
      languageEnv: (state) => state.global.languageEnv,
      languagePackage: (state) => state.global.languagePackage,
    }),
    abbrStyle() {
      const length = this?.abbr?.length;
      return {
        width: length == 6 ? '60px' : (length == 5 ? '55px' : '50px')
      };
    }
  },
  watch: {
    languageEnv: {
      async handler(value) {
        if(value){
          this.localesEnv = value;
        }
      },
      immediate: true,
      deep: true,
    },
    languagePackage: {
      async handler(value) {
        if(value){
          this.localesPackage = value;
        }
      },
      immediate: true,
      deep: true,
    }
  },
  setup(){
      /**语言包 - ref */
      const localesPackage = ref({});
      /**语言环境 - ref */
      const localesEnv = ref('en');
      return {
        localesPackage,
        localesEnv
      }
  }
};
</script>

<style lang="less" scoped>
p {
  padding: 0;
  margin: 0;
}
.block {
  width: 351px;
  margin: 0 auto;
  margin-bottom: 6px;
  // background: url("~@/assets/images/YuliApp/attribute/bg_l@2x.png");
  // background-size: 100% 100%;
  border-radius: 8px;
  background: rgba(137, 161, 211, 0.4);
//   &-shang {
//     display: block;
//     width: 100%;
//     height: 9px;
//     margin-bottom: -0.5px;
//   }
//   &-xia {
//     display: block;
//     width: 100.1%;
//     height: 10px;
//     margin-top: -0.5px;
//   }
  &-content {
    margin: 0 auto;
    box-sizing: border-box;
    width: 351px;
    padding: 11px 14px 20px 14px;
    // background: rgba(48, 58, 91, 1);
    // border-left: 1.1px solid rgba(24, 31, 54, 1);
    // border-right: 1px solid rgba(24, 31, 54, 1);
    &-abbr {
      width: 50px;//50 55 60
      height: 25px;
      background: rgba(31, 38, 102, 0.5);
      color: rgba(182, 210, 243, 1);
      font-size: 14px;
      font-weight: 600;
      font-family: "PingFang SC";
      text-align: left;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px 30px 0px 30px;
    }
    &-description {
      padding-bottom: 8px;
      display: flex;
      align-items: flex-start;
      // border-bottom: 1px solid rgba(24, 31, 54, 1);
      margin-bottom: 8px;
      &-title {
        width: 80px;
        margin-right: 6px;
        // color: rgba(158, 177, 215, 1);
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 14.4px;
      }
      &-content {
        width: 243px;
        color: rgba(209, 220, 231, 0.9);
        font-size: 13px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 15.6px;
      }
    }
    &-enhancement {
      display: flex;
      align-items: flex-start;
      &-title {
        width: 80px;
        margin-right: 6px;
        // color: rgba(158, 177, 215, 1);
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 14.4px;
      }
      &-content {
        width: 243px;
        color: rgba(209, 220, 231, 0.9);
        font-size: 13px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 15.6px;
      }
    }
  }
}
.block:last-child {
  margin-bottom: 0;
  padding-bottom: 6px;
}
</style>
