<template>
  <div class="attributes">
    <div
      class="attributes-return"
      :style="{ paddingTop: 15 + statusBarHeight + 'px' }"
    >
      <Back 
        :clickHandle="handleClosePage"
        :innerText="localesPackage?.['yuli_back']?.[localesEnv]"
      />
    </div>

    <div class="attributes-content">
      <AttributeBlock
        v-for="(item, index) in attributeData"
        :key="index"
        :abbr="item.Abbr"
        :description="item.Description"
        :enhancement_attributes="item.Enhancement_Attributes"
      ></AttributeBlock>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { onBeforeMount, onMounted, ref } from "vue";
import appBridging from "@/utils/appBridging.js";
import AttributeBlock from "./components/AttributeBlock.vue";
// import { ATTRIBUTENS_TYPE } from "./constants/attributes.js";
import {getYuliAttributesLanguage} from "@/api/language.js";
import store from "@/store";
import Back from "@/components/universal/Back";

export default {
  components: {
    AttributeBlock,
    Back,
  },
  computed: {
    ...mapState({
      init: (state) => state.global.init,
      statusBarHeight: (state) => state.global.statusBarHeight,
      languageEnv: (state) => state.global.languageEnv,
      languagePackage: (state) => state.global.languagePackage,
    }),
  },
  watch: {
    languageEnv: {
      async handler(value) {
        console.log('languageEnv: ', value);
        if(value){
          this.localesEnv = value;
        }
      },
      immediate: true,
      deep: true,
    },
    languagePackage: {
      async handler(value) {
        console.log('languagePackage: ', value);
        if(value){
          this.localesPackage = value;
          this.initHandle();
        }
      },
      immediate: true,
      deep: true,
    }
  },
  setup() {
    /**语言包 - ref */
    const localesPackage = ref({});
    /**语言环境 - ref */
    const localesEnv = ref('en');
    const attributeData = ref([]);

    onBeforeMount(async ()=>{
      appBridging.getSettingLaungage();
      const res = await getYuliAttributesLanguage();
      store.dispatch("global/setLanguagePackage", res);// 存到stroe
    })

    onMounted(() => {
      appBridging.getStatusBarHeight();
    });

    const initHandle = () => {
      const localesPackageLocal = localesPackage.value;
      const localesEnvLocal = localesEnv.value;
      attributeData.value = [
        {
          Abbr: localesPackageLocal?.['chiprule_cst']?.[localesEnvLocal],
          Description: localesPackageLocal?.['chiprule_cst_desc']?.[localesEnvLocal],
          Enhancement_Attributes: localesPackageLocal?.['chiprule_cst_attr']?.[localesEnvLocal],
        },
        {
          Abbr: localesPackageLocal?.['chiprule_qlt']?.[localesEnvLocal],
          Description: localesPackageLocal?.['chiprule_qlt_desc']?.[localesEnvLocal],
          Enhancement_Attributes: localesPackageLocal?.['chiprule_qlt_attr']?.[localesEnvLocal],
        },
        {
          Abbr: localesPackageLocal?.['chiprule_pcpt']?.[localesEnvLocal],
          Description: localesPackageLocal?.['chiprule_pcpt_desc']?.[localesEnvLocal],
          Enhancement_Attributes: localesPackageLocal?.['chiprule_pcpt_attr']?.[localesEnvLocal],
        },
        {
          Abbr: localesPackageLocal?.['chiprule_bty']?.[localesEnvLocal],
          Description: localesPackageLocal?.['chiprule_bty_desc']?.[localesEnvLocal],
          Enhancement_Attributes: localesPackageLocal?.['chiprule_bty_attr']?.[localesEnvLocal],
        },
        {
          Abbr: localesPackageLocal?.['chiprule_cbt']?.[localesEnvLocal],
          Description: localesPackageLocal?.['chiprule_cbt_desc']?.[localesEnvLocal],
          Enhancement_Attributes: localesPackageLocal?.['chiprule_cbt_attr']?.[localesEnvLocal],
        },
        {
          Abbr: localesPackageLocal?.['chiprule_exprf']?.[localesEnvLocal],
          Description: localesPackageLocal?.['chiprule_exprf_desc']?.[localesEnvLocal],
          Enhancement_Attributes: localesPackageLocal?.['chiprule_exprf_attr']?.[localesEnvLocal],
        },
        {
          Abbr: localesPackageLocal?.['chiprule_excst']?.[localesEnvLocal],
          Description: localesPackageLocal?.['chiprule_excst_desc']?.[localesEnvLocal],
          Enhancement_Attributes: localesPackageLocal?.['chiprule_excst_attr']?.[localesEnvLocal],
        },
      ];
      appBridging.closeLoading();
    }

    /**
     * 关闭页面
     */
    const handleClosePage = () => {
      appBridging.closePage();
    };
    return {
      handleClosePage,
      initHandle,
      attributeData,
      localesPackage,
      localesEnv
    };
  },
};
</script>

<style lang="less" scoped>
.attributes {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  // background-image: url("~@/assets/images/YuliApp/attribute/attribute_bg.png");
  // background-size: 100% 100%;
  // background: linear-gradient(180deg, #352d62ff 0%, #2d64a5ff 100%);
  background-image: url("~@/assets/images/YuliApp/howToPlay/page_bg.png");
  background-size: 100% 100%;
  &-return {
    width: 79px;
    height: 26px;
    text-align: left;
    margin-bottom: 18px;
    position: relative;
  }
  &-content {
    height: 95%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; 
    padding-bottom: 6px;
    &-block {
      width: 350px;
      margin: 0 auto;
    }
  }
  //隐藏滚动条
  .attributes-content::-webkit-scrollbar {
    display: none;
  }
  .attributes-content {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
