<template>
  <YuliAttributes></YuliAttributes>
</template>
<script>
import YuliAttributes from '@/components/rules/YuliAttributes' 
export default {
  components:{
    YuliAttributes,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped></style>
